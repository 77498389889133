const gameNameText = () => {
  if (window.location.pathname.includes('/slots/')) {
    const gameName = window.location.pathname
      .slice(7)
      .replace(/-/gi, ' ')
      .replace('/', '')
      .split(' ');

    for (let i = 0; i < gameName.length; i++) {
      gameName[i] = gameName[i][0].toUpperCase() + gameName[i].substr(1);
    }
    const gameNameText = gameName.join(' ');

    return gameNameText;
  }
};

export default gameNameText;
