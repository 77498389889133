import { Grid } from 'semantic-ui-react';
import { List, Image } from 'semantic-ui-react';

const Footer = () => {
  return (
    <Grid
      container
      doubling
      textAlign="center"
      columns={5}
      inverted
      divided="vertically"
      verticalAlign="middle"
    >
      <Grid.Row color="black">
        <Grid.Column className="footer-links" color="black">
          <List bulleted horizontal link inverted>
            <List.Item
              as="a"
              href="/terms/"
              to={'route'}
              target={'_blank'}
              rel={'noopener noreferrer nofollow'}
            >
              Terms and Conditions
            </List.Item>
          </List>
        </Grid.Column>

        <Grid.Column className="footer-links" color="black">
          <List bulleted horizontal link inverted>
            <List.Item
              as="a"
              href="/privacy/"
              to={'route'}
              target={'_blank'}
              rel={'noopener noreferrer nofollow'}
            >
              Privacy Policy
            </List.Item>
          </List>
        </Grid.Column>

        <Grid.Column className="footer-links" color="black">
          <List bulleted horizontal link inverted>
            <List.Item
              as="a"
              href="/cookies/"
              to={'route'}
              target={'_blank'}
              rel={'noopener noreferrer nofollow'}
            >
              Cookie Policy
            </List.Item>
          </List>
        </Grid.Column>

        <Grid.Column className="footer-links" color="black">
          <List bulleted horizontal link inverted>
            <List.Item
              as="a"
              href="mailto:info@amotismedia.com"
              to={'route'}
              target={'_blank'}
              rel={'noopener noreferrer nofollow'}
            >
              Contact Us
            </List.Item>
          </List>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row color="black">
        <Grid.Column className="footer-links" color="black">
          <List bulleted horizontal link inverted>
            <List.Item
              as="a"
              href="https://www.begambleaware.org/"
              to={'route'}
              target={'_blank'}
              rel={'noopener noreferrer nofollow'}
            >
              <Image src="https://storage.googleapis.com/brand-images/18%2B.svg" />
            </List.Item>
          </List>
        </Grid.Column>

        <Grid.Column className="footer-imgage" color="black">
          <List bulleted horizontal link inverted>
            <List.Item
              as="a"
              href="https://www.begambleaware.org/"
              to={'route'}
              target={'_blank'}
              rel={'noopener noreferrer nofollow'}
            >
              <Image src="https://storage.googleapis.com/brand-images/beGambleAware.svg" />
            </List.Item>
          </List>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row color="black">
        <div className="footer-text">
          <p>
            Copyright © 2022 slotgamesonline.co.uk. All Rights Reserved. By
            using our content, products & services you agree to our Terms of
            Service and Privacy Policy. Reproduction in whole or in part is
            strictly prohibited. Players must be 18 or over and located in the
            United Kingdom. This website contains information about a range of
            products and services. Certain details, including prices and special
            offers, are provided to us directly from our partners. These are
            subject to change at any time without prior notice. The information
            we share does not constitute legal or professional advice or
            forecast, and should not be treated as such.
          </p>
        </div>
      </Grid.Row>
    </Grid>
  );
};

export default Footer;
