import React from 'react';

const Link = ({ href, children }) => {
  if (
    !window.location.href.includes('gclid') &&
    !window.location.href.includes('fbclid')
  ) {
    return (
      <a
        href={href}
        to={'route'}
        target={''}
        rel={'noopener noreferrer nofollow'}
      >
        {children}
      </a>
    );
  }
  const gclid = window.location.href.slice(
    window.location.href.lastIndexOf('?')
  );
  href += gclid;
  const onClick = (event) => {};

  return (
    <a
      onClick={onClick}
      href={href}
      to={'route'}
      target={''}
      rel={'noopener noreferrer nofollow'}
    >
      {children}
    </a>
  );
};

export default Link;
