const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <p>
        Version 1.1
        <br />
        <br />
        Effective Date: 12th September 2021
        <br />
        <br />
        Date of Last Revision: 12th September 2021
        <br />
        <br />
        This privacy policy (“Privacy Policy”) governs the data collection
        practices of Amotis Media (“Amotis Media”, we”, “our” or “us”) as they
        relate to how users (“you”) interact with the website
        slotgamesonline.co.uk together with its subdomains, features, and
        services available thereon, the “Website”).
        <br />
        <br />
        This Privacy Policy provides important information &amp; disclosures
        around how and why we collect, share and use your data. The sub-headings
        and sections below are for not to be used for interpretive purposes but
        are solely placed to help with navigating the Privacy Policy.
        <br />
        <br />
        ACCEPTANCE OF THE TERMS: BY ACCESSING OR USING THE WEBSITE OR OUR
        SERVICES, YOU AGREE TO THE TERMS AND CONDITIONS SET FORTH IN THIS
        PRIVACY POLICY , INCLUDING TO THE COLLECTION AND PROCESSING OF THE
        INFORMATION. IF YOU DISAGREE TO ANY TERM PROVIDED HEREIN, YOU MAY NOT
        USE THE WEBSITE OR SERVICE. YOUR USE OF THIS WEBSITE INDICATES
        ACCEPTANCE OF THIS PRIVACY POLICY.
        <br />
        <br />
        INFORMATION COLLECTED AND STORED In general, we receive and collect your
        data because:
        <br />
        <br />
        (a) You have provided it to us; (b) We collect it from you in accordance
        with the provisions contained hereunder; and/or (c) Third parties we
        engage (such as data analytics providers) collect it from you on our
        behalf.
        <br />
        <br />
        1.1 Information You Give Us:
        <br />
        <br />
        There are instances where you may be providing us with personal
        information related to your usage of the site. These are:
        <br />
        <br />
        Filing a “Contact Us” request: by sending us a “Contact Us” request via
        an online form, by sending an email to an email address that we display,
        by using a feedback or reporting feature, or by other means, you may be
        required to provide us with certain personal information, such as your
        name and email address. Commenting &amp; Subscribing: parts of the
        Website may offer users the opportunity to leave comments on blog posts,
        reviews &amp; articles. You may also be able to subscribe to various
        newsletters operated by us. In each of the foregoing cases, your
        interaction (such as responding on the blog forum, subscribing to a
        newsletter, or participating in a survey) may require you to provide
        certain personal information such as your name and email address. Social
        Registration: in some cases, you may be able to use social registration
        to comment, register or sign up to using your Facebook login. If you do
        so, you authorize us to access certain Facebook account information,
        such as your public Facebook profile(consistent with your privacy
        settings in Facebook). Lead Generation: the Website lists and compares
        various businesses and commercial entities (collectively, “Partners”)
        and their respective products and/or services (collectively,
        “Products”). For more information regarding a Partner’s Product g, or to
        pursue a transaction with a Partner, you would click through to the
        Partner’s website or landing page. Such pages may prompt you for
        additional information such as your name, email address or phone number
        (“Landing Page Info”). We are not responsible for the privacy practices
        of any such third parties, and once you leave the Website via a link or
        enable a third-party service, you should check the applicable privacy
        policy of the third-party site carefully. Your information is governed
        by their privacy statements. 1.2 Information We Automatically Collect
        From You:
        <br />
        <br />
        Apart from any personal data you maybe be supplying to us as listed in
        1.1, we collect additional technical and aggregated information about
        you that is non-personal non-identifiable information which may be made
        available or gathered via your use of the Website.
        <br />
        <br />
        Behavioural, Device and Usage Data: we deploy various tracking
        technologies on the Website (which may include 3rd party technologies)
        to help us collect aggregated trend data and usage analytics. We do this
        to analyse trends to help optimise our Website and track user movement
        and user clicks through to other websites. The type of data may include
        (but is not limited to) internet protocol (IP) addresses, MAC address,
        device type, browser type, browser version, operating system type,
        screen resolution, user agent, user identifier, user language, URLs and
        traffic channels (such as the previous URLs or platforms you visited),
        keyword searches and bids, city, region code, country, latitude,
        Internet Service Provider (ISP), date/time stamp of Website use,
        referring/exit pages, clicks, timing for clicks and page-views, and
        clicked pages. Cookies and Other Tracking Technologies. The Website may
        use “cookies” and other tracking technologies (such as “web beacons” and
        “pixel tags”). Please refer to our Cookie Policy for more information.
        <br />
        <br />
        Information We Get from 3rd Parties: We may receive additional data from
        3rd parties that help us with our business operations, that help us
        understand how our users use the Website, and to provide general
        analytics with regards to Website usage. Such data is in most cases
        aggregated, non-personal, non-identifiable data.
        <br />
        <br />
        1.3.1 Website Analytics: We receive a variety of anonymised, aggregated
        site usage analytics data from web site analytics companies, such as
        Google Analytics, Bing SearchAds or Google AdWords.
        <br />
        <br />
        HOW YOUR PERSONAL DATA IS USED Partners and Products. We may send or
        otherwise share your Landing Page Info to/with the Partner about whom
        (or about whose Product) you wanted to receive more information or with
        which you have expressed interest in pursuing a transaction. You
        acknowledge that: (a) the Partners may contact you using the Landing
        Page Info which you submitted; (b) we do not control the way in which
        Partners may contact you, or the subject matter of such contact; and (c)
        each Partner is required to have its own privacy policy, and so it won’t
        necessarily adhere to this Privacy Policy and (d) Partners may be based
        (and their servers may be located) anywhere in the world. We may also
        contact you to discuss and share information about Partners and their
        respective Products, as well as about partners, products, and services
        listed on other websites that are affiliated with us. The way in which
        we contact you may be via email, phone, cell phone, and/or text messages
        (SMS/MMS), and we may contact you using Landing Page Info as well as
        personal information collected from you as described in Section &lrm;1.1
        To Generally Provide You With the Website: We will use your personal
        information to: (a) administer and make available the Website to you;
        (b) further develop and generally improve the Website; (c) send you
        updates, promotions, offerings, or other news of/about the Website; (d)
        respond to your inquiries and other communications with you; (e)
        identify or authenticate your access and use of the Website; and/or (f)
        send you advertisements or other third party marketing content. Transfer
        To Affiliates: We may send or otherwise share your personal information
        to/with our affiliated entities for the purpose of storing such
        information on our behalf, or for other processing needs. These
        affiliated entities may be based (and their servers may be located)
        anywhere in the world. We require these affiliated entities to agree to
        process such personal information in accordance with this Privacy Policy
        and applicable law. Third Parties: We may send or otherwise share your
        personal information to/with various third parties that help us with our
        business operations, that help us understand how our users use the
        Website, and to provide general analytics with regards to Website usage.
        These 3rd parties will have their own privacy policies. For more
        information on how we share data with 3rd parties, please see 3. Sharing
        Data with 3rd Enforcement: We may share your personal information with
        any third party if we believe that disclosure of such information is
        helpful or reasonably necessary to: (a) comply with any applicable law,
        regulation, legal process, or governmental request; (b) enforce the
        Privacy Policy, including investigations of potential violations
        thereof; (c) detect, prevent, or otherwise address fraud or security
        issues; and/or (d) protect against harm to the rights, property or
        safety of our company , our affiliated entities, our users, yourself,
        and/or the public.
        <br />
        <br />
        SHARING DATA WITH THIRD PARTIES We may share your information, solely to
        the extent needed to enforce our policies (including our policies and
        agreements), and investigations of potential violations thereof,
        including without limitations, investigate, detect, prevent, or take
        action regarding illegal activities or other wrongdoing, suspected
        fraud, security or technical issues
        <br />
        <br />
        We may share your information to respond to user’s support requests; to
        respond to claims the rights of third-parties were violated including to
        respond to claims that contact information (e.g. name, email address,
        etc.) of a third-party has been posted or transmitted without their
        consent or as a form of harassment.
        <br />
        <br />
        We will share your information, solely to the extent needed to comply
        with any applicable law, regulation, legal process or governmental
        request (i.e., to comply with courts injunction, comply with tax
        authorities, etc.).
        <br />
        <br />
        We will we will share your information, solely to the extent needed to
        establish or exercise our rights to defend against legal claims.
        <br />
        <br />
        We will share your information, solely to the extent needed to prevent
        harm to the rights, property or safety of us, our users, yourself or any
        third party; or for the purpose of collaborating with law enforcement
        agencies or in case we find it necessary in order to enforce
        intellectual property or other legal rights.
        <br />
        <br />
        We may share your data with our parent company, any subsidiaries, joint
        ventures, or other companies under common control solely if and when
        applicable or necessary for the purposes described in this Privacy
        Policy.
        <br />
        <br />
        We may share Information, including personal information, in the event
        of a corporate transaction (e.g. sale of a substantial part of our
        business, merger, consolidation or asset sale). In the event of the
        above, our affiliated companies or acquiring company will assume the
        rights and obligations as described in this Privacy Policy.
        <br />
        <br />
        We may disclose your personal information to third parties upon your
        explicit consent, in order to enable said partners to complete various
        functions (e.g., Trust Pilot sending you a rating questionnaire
        regarding our services). Please note that once we share your information
        with third party, that information becomes subject to the other third
        party’s privacy practices.
        <br />
        <br />
        We may share your data to other third parties (such as ad networks,
        advertising companies, service providers, media and other interested
        parties) for the purpose of developing or delivering advertising and
        enhancing the users’ experience (as specified below).
        <br />
        <br />
        For avoidance of doubt, we may transfer and disclose Non-personal
        information to third parties at our own discretion, all subject to this
        Privacy Policy and compliance with applicable laws.
        <br />
        <br />
        If we combine personal information with non-personal Information, the
        combined information will be treated as personal information for as long
        as it remains combined.
        <br />
        <br />
        HOW LONG DO WE KEEP YOUR DATA FOR? We may rectify, replenish or remove
        incomplete or inaccurate information, at any time and at our own
        discretion. Please note that unless you instruct us otherwise we retain
        the information we collect for as long as needed to provide our Service
        and to comply with our legal obligations, resolve disputes and enforce
        our agreements.
        <br />
        <br />
        YOUR RIGHTS By law, you have a number of rights when it comes to your
        personal information. Further information and advice about your rights
        can be obtained from the data protection regulator in your country.
        <br />
        <br />
        The right to object to processing: You have the right to object to
        certain types of processing, including processing for direct marketing
        (i.e. if you no longer want to be contacted with potential
        opportunities). The right to be informed: You have the right to be
        provided with clear, transparent and easily understandable information
        about how we use your information and your rights. This is why we’re
        providing you with the information in this Privacy Policy. The right of
        access: You have the right to obtain access to your information (if
        we’re processing it), and certain other information (similar to that
        provided in this Privacy Policy). This is so you’re aware and can check
        that we’re using your information in accordance with data protection
        law. The right to rectification: You are entitled to have your
        information corrected if it’s inaccurate or incomplete. The right to
        erasure: This is also known as ‘the right to be forgotten’ and, in
        simple terms, enables you to request the deletion or removal of your
        information where there’s no compelling reason for us to keep using it.
        This is not a general right to erasure; there are exceptions. The right
        to restrict processing: You have rights to ‘block’ or suppress further
        use of your information. When processing is restricted, we can still
        store your information, but may not use it further. We keep lists of
        people who have asked for further use of their information to be
        ‘blocked’ to make sure the restriction is respected in future. The right
        to data portability: You have rights to obtain and reuse your personal
        information for your own purposes across different services. For
        example, if you decide to switch to a new provider, this enables you to
        move, copy or transfer your information easily between our IT systems
        and theirs safely and securely, without affecting its usability. The
        right to lodge a complaint: You have the right to lodge a complaint
        about the way we handle or process your personal information with your
        national data protection regulator. The right to withdraw consent: If
        you have given your consent to anything we do with your personal
        information, you have the right to withdraw your consent at any time
        (although if you do so, it does not mean that anything we have done with
        your personal information with your consent up to that point is
        unlawful). This includes your right to withdraw consent to us using your
        personal information for marketing purposes. We usually act on requests
        and provide information free of charge, but may charge a reasonable fee
        to cover our administrative costs of providing the information for:
        <br />
        <br />
        baseless or excessive/repeated requests, or • further copies of the same
        information. Alternatively, we may be entitled to refuse to act on the
        request.
        <br />
        <br />
        Please consider your request responsibly before submitting it. We’ll
        respond as soon as we can. Generally, this will be within one month from
        when we receive your request but, if the request is going to take longer
        to deal with, we’ll come back to you and let you know.
        <br />
        <br />
        MINORS
        <br />
        <br />
        You represent and warrant that you are at least eighteen (18) years of
        age and of legal competence to enter into this Privacy Policy . We do
        not use the Website to knowingly solicit data from or market to children
        under the age of eighteen (18). We request that such individuals do not
        provide personal information through our Website . We reserve the right
        to request proof of age at any stage so that we can verify that minors
        under the age of eighteen (18) are not using the Website. If you become
        aware or have any reason to believe that a child under the age of
        eighteen (18) has shared any information with us, please contact us and
        we take reasonable steps to ensure that such information is deleted from
        our files.
        <br />
        <br />
        DIRECT MARKETING
        <br />
        <br />
        We may share your email address, name and/or mobile phone number, should
        these have been collected, with our marketing partners and/or internally
        with the purpose of providing you marketing offers, deliver newsletters
        and rating questionnaires on our behalf.
        <br />
        <br />
        You can opt out at any time from receiving such communications by either
        following the “unsubscribe” links at the bottom of electronic
        communications, following the op-out instructions on any mobile
        communications or by contacting us directly through our Contact US form
        or emailing us at info@amotismedia.com
        <br />
        <br />
        COOKIES, WEB BEACONS AND THIRD-PARTY COOKIES
        <br />
        <br />
        When you use our Website, we use “Cookies” for various purposes. A
        “Cookie” is a small piece of information that a website assigns and
        stores on your computer while you are viewing a website. The cookies
        used by the Website are created per session and do not include any
        personal information about you. For more information regarding how the
        Website uses cookies, please refer to our Cookie Policy.
        <br />
        <br />
        FURTHER QUESTIONS?
        <br />
        <br />
        If you have any questions about this Policy, please contact us via our
        Contact Us form or at info@amotismedia.com
      </p>
    </div>
  );
};

export default PrivacyPolicy;
