import React, { useState } from 'react';
import { Rating, Button, Icon, Label } from 'semantic-ui-react';

// const Like = ({ rating }) => {
//   const [count, setCount] = useState(rating);
//   const [like, setLike] = useState('off');
//   const [basicColor, setBasicColor] = useState('blue');
//   const [icon, setIcon] = useState('thumbs up outline');

//   const onClick = () => {
//     if (like === 'off') {
//       setCount(count + 1);
//       setLike('on');
//       setBasicColor('blue');
//       setIcon('thumbs up');
//       return;
//     }

//     setCount(count - 1);
//     setLike('off');
//     setBasicColor('blue');
//     setIcon('thumbs up outline');
//   };
//   return (
//     <div>
//       <Button as="div" labelPosition="right">
//         <Button onClick={() => onClick()} icon color={basicColor}>
//           <Icon name={icon} />
//           Like
//         </Button>
//         <Label
//           onClick={() => onClick()}
//           icon
//           color={basicColor}
//           as="a"
//           basic
//           color={basicColor}
//           pointing="left"
//         >
//           {count}
//         </Label>
//       </Button>
//     </div>
//   );
// };

const Like = ({ rating, star }) => {
  return (
    <div>
      <Rating icon="star" defaultRating={star} maxRating={5} />
      <div>({rating})</div>
    </div>
  );
};

export default Like;
