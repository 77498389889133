import Header from '../components/Header';
import TopIcons from '../components/TopIcons';
import TopText from '../components/TopText';
import Table from '../components/Table';
import Bottom from '../components/Bottom';
import Footer from '../components/Footer';
import { Divider } from 'semantic-ui-react';

const WelcomeBonuses = () => {
  return (
    <div>
      <Header />
      <Divider inverted />
      <TopIcons />
      <Divider hidden />
      <TopText />
      <Divider hidden />
      <Divider hidden />
      <Table />
      <Divider hidden />
      <Bottom />
      <Divider hidden />
      <Footer />
    </div>
  );
};

export default WelcomeBonuses;
