import Header from '../components/Header';
import TermsPolicy from '../components/TermsPolicy';
import Footer from '../components/Footer';
import { Divider } from 'semantic-ui-react';

const Terms = () => {
  return (
    <div>
      <Header />
      <Divider inverted />
      <TermsPolicy />
      <Divider hidden />
      <Footer />
    </div>
  );
};

export default Terms;
