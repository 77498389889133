import { Grid } from 'semantic-ui-react';

const Bottom = () => {
  return (
    <Grid
      className="bottom-text-container"
      container
      doubling
      textAlign="justified"
      columns={5}
      inverted
      divided="vertically"
      verticalAlign="middle"
    >
      <Grid.Row color="black">
        <div className="bottom-text">
          <h3 style={{ textAlign: 'center' }}>Trust and Safety </h3>
          <p>
            Before listing a Casino, we thoroughly check it to ensure that it is
            fully UK licensed and is regulated by the UK Gambling Commission.
          </p>
          <h3 style={{ textAlign: 'center' }}>How we rank </h3>
          <p>
            Each casino is thoroughly reviewed to ensure that we can list it as
            a reccommended brand. First we analyse the casino’s bonuses and
            software to ensure that the bonuses are realistic with fair terms
            and conditions, whilst the software is modern and offers players a
            good experience. Assuming the bonuses and software check results are
            positive, we test the sites to make sure that they offer a selection
            of ways to deposit and withdraw in via safe and secure payment
            processors. Next we see if they offer good customer support to their
            players and by which mediums it is possible to connect with them
            i.e. phone, email, chat and finally how quickly they respond.
          </p>
          <h3 style={{ textAlign: 'center' }}>Bonuses explained </h3>{' '}
          <p>
            Casinos offer bonuses to new players as a way of welcoming them.
            These Bonuses have varying terms and conditions attached, the two
            most important are the wagering requirement, which is the amount of
            time you need to stake the bonus in order to be able to withdraw it,
            and the minimum deposit, which is the minimum amount that should be
            deposited in order to be eligible for the bonus.
          </p>
          <h3 style={{ textAlign: 'center' }}>Advertising disclosure </h3>
          <p>
            slotgamesonline.co.uk is an independent professional comparison site
            supported by referral fees from the sites which are ranked on the
            site. The Slots site and information that we present are from
            companies from which slotgamesonline.co.uk receives compensation.
            This compensation impacts the ranking of the sites. Other factors,
            including our own opinions, your location and likelihood of signing
            up, may also impact how the ranking of the sites appears to a
            particular user. slotgamesonline.co.uk cannot and does not present
            information about every Slots site or Slots site offer available.
          </p>
        </div>
      </Grid.Row>
    </Grid>
  );
};

export default Bottom;
