import { Button, Popup } from 'semantic-ui-react';

const PopupExampleBasic = () => (
  <Popup
    trigger={
      <Button floated="right" className="disclosure">
        Advertising Disclosure
      </Button>
    }
    content="slotgamesonline.co.uk is an independent professional comparison site
    supported by referral fees from the sites which are ranked on the site. The Slots site and information
    that we present are from companies from which slotgamesonline.co.uk receives compensation. This
    compensation impacts the ranking of the sites. Other factors, including our own opinions, your location
    and likelihood of signing up, may also impact how the ranking of the sites appears to a particular user.
    slotgamesonline.co.uk cannot and does not present information about every Slots site or Slots site offer
    available."
    basic
  />
);

export default PopupExampleBasic;
