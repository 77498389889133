import React, { useEffect, useState } from 'react';

const useGameText = () => {
  const [gameTitle, setGameTitle] = useState('');
  const [gameText, setGameText] = useState('');

  useEffect(() => {
    fetchData();
  }, []);

  const gameName = window.location.pathname.slice(7).replace('/', '');
  const path = `https://storage.googleapis.com/slot-game-text/${gameName}.json`;

  const fetchData = async () => {
    try {
      const response = await fetch(path, {
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
      });
      const data = await response.json();
      setGameTitle(data.GameName);
      setGameText(data.GameText);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return { gameTitle, gameText };
};

export default useGameText;
