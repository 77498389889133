import { Grid } from 'semantic-ui-react';
import Link from './Link';

const Header = () => {
  return (
    <Grid className="header" container textAlign="center" columns={3}>
      <Link href="/">
        <img
          className="header-logo"
          src="https://storage.googleapis.com/brand-images/slotgamesonlineLogo.svg"
        />
      </Link>
    </Grid>
  );
};

export default Header;
