const useGameImage = () => {
  const gameName = window.location.pathname
    .slice(7)
    .replace(/-/gi, '%20')
    .replace('/', '');
  const image = `https://storage.googleapis.com/slot-game-images/${gameName}.jpg`;

  return image;
};

export default useGameImage;
