import React from 'react';
import { Helmet } from 'react-helmet';
import gameNameText from '../hooks/useProductText';

const Meta = () => {
  return (
    <div className="application">
      {window.location.pathname.includes('/slots/') ? (
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`Best UK sites to play ${gameNameText()} slot online`}</title>
          <meta
            name="description"
            content={`The top UK slot sites to play ${gameNameText()} slot online `}
          />
          <link rel="canonical" href={window.location.href} />
        </Helmet>
      ) : (
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`Best UK sites to play slots online`}</title>
          <meta
            name="description"
            content={`The top UK slot sites to play slots online `}
          />
          <link rel="canonical" href={window.location.href} />
        </Helmet>
      )}
    </div>
  );
};

export default Meta;
