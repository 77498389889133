import { Grid, Image, Container, Header } from 'semantic-ui-react';
import useGameImage from '../hooks/useGameImage';
import useGameText from '../hooks/useGameText';

const GameCard = () => {
  return (
    <div>
      <Grid container textAlign="center">
        <Grid.Row>
          <Image
            rel="preload"
            as="image"
            className="game-image"
            src={useGameImage()}
          />
        </Grid.Row>
      </Grid>
      <Grid textAlign="center">
        <Grid.Row only="mobile tablet" textAlign="justified">
          <Container fluid>
            <Header as="h2" textAlign="center" inverted>
              {useGameText().gameTitle}
            </Header>
            <p
              style={{ color: 'white', marginLeft: '10%', marginRight: '10%' }}
            >
              {useGameText().gameText}
            </p>
          </Container>
        </Grid.Row>

        <Grid.Row only="computer" textAlign="justified">
          <Container fluid>
            <Header as="h2" textAlign="center" inverted>
              {useGameText().gameTitle}
            </Header>
            <p
              style={{ color: 'white', marginLeft: '20%', marginRight: '20%' }}
            >
              {useGameText().gameText}
            </p>
          </Container>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default GameCard;
