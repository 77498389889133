import useGameImage from '../hooks/useGameImage';
import { Grid, Image } from 'semantic-ui-react';

const GameImage = () => {
  return (
    <Grid container textAlign="center">
      <Grid.Row>
        <Image
          rel="preload"
          as="image"
          className="game-image"
          src={useGameImage()}
        />
      </Grid.Row>
    </Grid>
  );
};

export default GameImage;
