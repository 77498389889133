import { Grid } from 'semantic-ui-react';
import Disclosure from './Disclosure';

const TopIcons = () => {
  return (
    <Grid className="top" container textAlign="center" columns={3}>
      <Grid.Row className="top-icons">
        <Grid.Column>
          <img
            className="top-icon-image"
            src="https://storage.googleapis.com/brand-images/brit.svg"
          />
          <p className="top-icon-text">UK Licensed</p>
        </Grid.Column>
        <Grid.Column>
          <img
            className="top-icon-image"
            src="https://storage.googleapis.com/brand-images/pound.svg"
          />
          <p className="top-icon-text">Fast Withdrawal</p>
        </Grid.Column>
        <Grid.Column>
          <img
            className="top-icon-image"
            src="https://storage.googleapis.com/brand-images/safe.svg"
          />
          <p className="top-icon-text">100% Secure</p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={1}>
        <Grid.Column>
          <Disclosure />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};
export default TopIcons;
