import Header from '../components/Header';
import TopIcons from '../components/TopIcons';
import TopText from '../components/TopText';
import TableSlots from '../components/TableSlots.js';
import Bottom from '../components/Bottom';
import Footer from '../components/Footer';
import { Divider } from 'semantic-ui-react';
import GameCard from '../components/GameCard';

const SlotGames = ({ searchParams }) => {
  return searchParams.includes('campaignid') ? (
    //Pages for PPC
    <div>
      <Header />
      <Divider inverted />
      <TopText />
      <Divider hidden />
      <Divider hidden />
      <Divider hidden />
      <Divider hidden />
      <TableSlots />
      <Divider hidden />
      <TopIcons />
      <Divider hidden />
      <GameCard />
      <Bottom />
      <Divider hidden />
      <Footer />
    </div>
  ) : (
    //Pages for SEO
    <div>
      <Header />
      <Divider inverted />
      <TopText />
      <Divider hidden />
      <GameCard />
      <Divider hidden />
      <TopIcons />
      <Divider hidden />
      <Divider hidden />
      <TableSlots />
      <Divider hidden />
      <Bottom />
      <Divider hidden />
      <Footer />
    </div>
  );
};

export default SlotGames;
