const CookiePolicy = () => {
  return (
    <div className="cookie-policy">
      <h2> Cookie Policy</h2>
      <p>
        Cookies This Cookie Policy applies to the www.slotgamesonline.co.uk
        (“Amotis Media”) website (the “site”). By accessing and using the Site,
        you consent to the use of cookies.
        <br />
        <br />
        We expressly reserve the right to modify this Policy from time to time.
        If there are any material changes to this Cookie Policy, you will be
        notified by email, or by the posting a prominent notice throughout the
        Site prior to the change becoming effective. We encourage you to
        periodically review this page for the latest information on our privacy
        practices. Your continued use of the Site constitutes your agreement to
        be bound by such changes to this Policy. Your only remedy, if you do not
        accept the terms of this Policy, is to discontinue use of the Site.
        <br />
        <br />
        This Cookie Policy is part of our Privacy Policy and explains:
        <br />
        <br />
        What cookies are What cookies are used when you use the Site How third
        parties use cookies on the Site Your cookie options Interest-Based
        Advertising What is a cookie? A cookie is a small text file that is
        stored in your web browser that allows Amotis Media or a third party to
        recognise you. Cookies might be used for the following purposes: (1) to
        enable certain functions; (2) to provide analytics; (3) to store your
        preferences; and (4) to enable ad delivery and behavioural
        advertising.Cookies can either be session cookies or persistent cookies.
        A session cookie expires automatically when you close your browser. A
        persistent cookie will remain until it expires or you delete your
        cookies. Expiration dates are set in the cookies themselves; some may
        expire after a few minutes while others may expire after multiple years.
        Cookies placed by the website you’re visiting are sometimes called
        “first party cookies,” while cookies placed by other companies are
        sometimes called “third party cookies.” What cookies are used when I use
        the Site?When you access and/or use the Site, Amotis Media or a third
        party may place a number of cookies in your browser. Some of the cookies
        will only be used if you use certain features or select certain
        preferences, and some cookies will always be used.Each cookie serves one
        of four different purposes: Essential Cookies: These first party cookies
        allow users to use a feature of the Site such as: (i) staying logged in,
        or (ii) making purchases. Analytics Cookies: These cookies track
        information about how the Site are being used so that we can make
        improvements and report on our performance. We may also use analytics
        cookies to test new ads, pages or features to see how users react to
        them. Analytics cookies may either be first party or third party
        cookies. Preference Cookies: These first party cookies store your Site
        preferences. Ad Targeting Cookies: These third party cookies (also known
        as “behavioural” or “targeted” advertising are placed by advertising
        platforms or networks in order to: (i) deliver ads and tracks ad
        performance, and (ii) enable advertising networks to deliver ads that
        may be relevant based upon your activities. Finally, we may set cookies
        within emails we send to you (if you have consented to receiving emails
        from us). These cookies are used to track how often our emails are
        opened and clicked on by our customers. You can manage email cookies in
        the same way as website cookies, as explained above.
        <br />
        <br />
        How do third parties use cookies on the Site?Third party companies like
        analytics companies and ad networks generally use cookies to collect
        user information on an anonymous basis. They may use that information to
        build a profile of your activities on the Site and other websites that
        you’ve visited. What are my cookie options?If you don’t like the idea of
        cookies or certain types of cookies, you can change your browser’s
        settings to delete cookies that have already been set and to not accept
        new cookies. To learn more about how to do this, visit the help pages of
        your browser. Please note, however, that if you delete cookies or do not
        accept them, you might not be able to use all of the features we offer,
        you may not be able to store your preferences, and some of our pages
        might not display properly.You may also opt out of third party cookies
        by following the instructions provided by each third party in its
        privacy policy. How to manage cookies?As we explain in our Cookie
        Policy, cookies help you to get the most out of our websites.If you do
        disable our cookies you may find that certain sections of our website do
        not work.How to disable behaviourally targeted advertising cookiesIf you
        are concerned about behaviourally targeted advertising cookies (which
        serve you advertisements based on your use of www.slotgamesonline.co.uk
        and other websites), users based in the European Union can visit
        www.youronlinechoices.eu and users based in the US can visit
        http://www.aboutads.info/choices/ to opt out of these third party
        cookies.The above websites are not slotgamesonline.co.uk sites and we
        are not responsible for their content.How to enable and disable cookies
        using your browserGoogle Chrome Click the wrench icon on the browser
        toolbar Select Settings Click ‘Show advanced settings’ In the “Privacy”
        section, click the ‘Content settings’ button To enable cookies in the
        “Cookies” section, pick ‘Allow local data to be set’, this will enable
        both first-party and third-party cookies. To allow only first-party
        cookies pick ‘Block all third-party cookies without exception’ To
        disable cookies, in the “Cookies” section, pick ‘Block sites from
        setting any data’ Note there are various levels of cookie enablement and
        disablement in Chrome. For more information on other cookie settings
        offered in Chrome, refer to the following page from Google:
        http://support.google.com/chrome/bin/answer.py?hl=en&answer=95647
        Microsoft Internet Explorer 6.0, 7.0, 8.0
        <br />
        <br />
        Click on ‘Tools’ at the top of your browser window and select ‘Internet
        Options’ In the options window navigate to the ‘Privacy’ tab To enable
        cookies: Set the slider to ‘Medium’ or below To disable cookies: Move
        the slider to the top to block all cookies Note there are various levels
        of cookie enablement and disablement in Explorer. For more information
        on other cookie settings offered in Internet Explorer, refer to the
        following page from Microsoft:
        http://windows.microsoft.com/en-GB/windows-vista/Block-or-allow-cookies
        Mozilla Firefox
        <br />
        <br />
        Click on ‘Tools’ at the browser menu and select ‘Options’ Select the
        Privacy panel To enable cookies: Check ‘Accept cookies for sites’ To
        disable cookies: Uncheck ‘Accept cookies for sites’ Note there are
        various levels of cookie enablement and disablement in Firefox. For more
        information, refer to the following page from Mozilla:
        http://support.mozilla.org/en-US/kb/Enabling%20and%20disabling%20cookies
        Opera
        <br />
        <br />
        Click on ‘Setting’ at the browser menu and select ‘Settings’ Select
        ‘Quick Preferences’ To enable cookies: check “Enable Cookies” To disable
        cookies: uncheck “Enable Cookies” Note there are various levels of
        cookie enablement and disablement in Opera. For more information on
        other cookie settings offered in Opera, refer to the following page from
        Opera Software: http://www.opera.com/browser/tutorials/security/privacy
        Safari on OSX
        <br />
        <br />
        Click on ‘Safari’ at the menu bar and select the ‘Preferences’ option
        Click on ‘Security’ To enable cookies: In the ‘Accept cookies’ section
        select ‘Only from site you navigate to’ To disable cookies: In the
        ‘Accept cookies’ section select ‘Never’ Note there are various levels of
        cookie enablement and disablement in Safari. For more information on
        other cookie settings offered in Safari, refer to the following page
        from Apple:
        http://docs.info.apple.com/article.html?path=Safari/3.0/en/9277.html All
        other browsers
        <br />
        <br />
        Please look for a “help” function in the browser or contact the browser
        provider.
        <br />
        <br />
        Do you use any other user tracking technologies?We use additional
        technologies to help track user activities and preferences. For example,
        we use web beacons (also known as clear gifs, pixel tags or web bugs).
        Web beacons are tiny graphics (about the size of period) with a unique
        identifier that are embedded invisibly on web pages or emails. They are
        used to track user activities and communicate with cookies. You cannot
        opt out of web beacons used in webpages, but you can limit their use by
        opting out of the cookies they interact with. You can opt out of web
        beacons used in emails by setting your email client to render emails in
        text mode only. Finally, we use local storage to facilitate certain
        functions, but we do not retain the data captured via local storage.
        Interest Based AdvertisingWe and third parties engage in interest-based
        advertising provided by vendors in order to deliver advertisements and
        personalised content that we and other advertisers believe will be of
        interest to you. To the extent third-parties are using cookies or other
        technologies to perform these services, Amotis Media does not control
        the use of this technology or the resulting information for online,
        mobile, or email advertising, and is not responsible for any actions or
        policies of such third parties. Advertisements, emails, and other
        messages may be delivered to you by Amotis Media or its service
        providers based on your online or mobile behaviour, your search
        activity, your geographic location or other information that is
        collected by us or obtained from third parties. Some of our vendors and
        we may use our own or third-party aggregated or anonymised personal
        information, demographic data, and other inferred commercial interests
        to assist in the delivery of our advertisements to you.In addition, we
        and our business partners use third parties to establish deterministic
        or probabilistic connections among devices (such as smartphones,
        tablets, and computers) to deliver more relevant advertising to you and
        for advertising analytics and reporting purposes. This means that
        information about your use of websites or applications on your current
        device may be combined with information from your other devices. We also
        may share this information and other inferences with third parties to
        allow them to target advertising, personalise content, or analyse
        behaviour. This allows, for example, advertisements you see on your
        tablet to be based on activities you engaged in on your smartphone.
        These business partners may share and combine information from cookies
        with identifiers (such as device IDs assigned by Google or Apple) and IP
        addresses to make connections among related devices. This also allows
        for a more personalised experience across the Site.
      </p>
    </div>
  );
};

export default CookiePolicy;
