import Header from '../components/Header';
import CookiePolicy from '../components/CookiePolicy';
import Footer from '../components/Footer';
import { Divider } from 'semantic-ui-react';

const Cookies = () => {
  return (
    <div>
      <Header />
      <Divider inverted />
      <CookiePolicy />
      <Divider hidden />
      <Footer />
    </div>
  );
};

export default Cookies;
