import Listing from './Listing';

const link = [
  {
    url: 'http://click.slotgamesonline.co.uk/',
  },
  {
    LogoUrl: 'https://www.slotgamesonline.co.uk/images/',
  },
];

const brands = [
  // {
  //   name: 'bet365',
  //   linkName: 'bet365',
  //   offer: 'Get up to 60 Free Spins',
  //   logo: 'https://storage.googleapis.com/brand-images/bet365LogoMobile.svg',
  //   terms:
  //     'Min £10 in lifetime deposits required. Offer must be claimed within 30 days of registering a bet365 account. Reveal prizes of 5, 10 or 20 Free Spins; three spins on Free Spins reels available within seven days, 24 hours between each spin. Max. prize, game restrictions, time limits and T&Cs apply.',
  //   fullTerms:
  //     'Min £10 in lifetime deposits required. Offer must be claimed within 30 days of registering a bet365 account. Reveal prizes of 5, 10 or 20 Free Spins; three spins on Free Spins reels available within seven days, 24 hours between each spin. Max. prize, game restrictions, time limits and T&Cs apply.',
  //   comment: 'Players Choice',
  //   link: link[0].url,
  //   rating: 3654,
  //   star: 4,
  // },
  // {
  //   name: '777',
  //   linkName: '777-v1',
  //   offer: '200% Bonus Up To £500',
  //   logo: 'https://storage.googleapis.com/brand-images/TrippleSevenLogoDesktop.svg',
  //   terms: 'New players only, 18+ T&Cs and wagering requirments apply',
  //   comment: 'Editors Choice',
  //   link: `https://mmwebhandler.aff-online.com/C/40476?sr=1750343&anid=`,
  //   rating: 2969,
  //   star: 5,
  // },
  {
    name: '888',
    linkName: '888-v1',
    offer: '88 Free Spins + 100% up to £100',
    logo: 'https://storage.googleapis.com/brand-images/tripleEightLogoDesktop.svg',
    terms: 'New players only, 18+ T&Cs and wagering requirments apply',
    fullTerms:
      'New & exclusive players only.  min deposit. To receive the bonus wager 50 x (deposit amount) within 21 days. To withdraw bonus & related wins, wager 50 x (bonus amount) within 21 days. This offer may not be combined with any other offer. Deposit balance is available for withdrawal at any time. General withdrawal restrictions & full T&Cs apply.',
    comment: 'Oldie But Goodie',
    link:
      // `https://mmwebhandler.aff-online.com/C/42280?sr=1750343&anid=`
      `https://mmwebhandler.aff-online.com/C/40346?sr=1835596&anid=`,
    rating: 3024,
    star: 5,
  },

  {
    name: 'PlayOJO',
    linkName: 'playojo',
    offer: 'Get 50 Free Spins + 30 Extra Spins On Your First Deposit',
    logo: 'https://storage.googleapis.com/brand-images/PlayOJOLogoDesktop.svg',
    terms: 'New players only, 18+ T&Cs and wagering requirments apply',
    comment: 'No wagering Requirements',
    link: 'https://site.gotoplayojo.com/index.php?aname=amotismedia&zone_id=luckyfruity&dyn_id=',
    rating: 907,
    star: 5,
  },

  {
    name: 'Casino Gods',
    linkName: 'casinogods',
    offer: '100% Up To £100 And 300 Free Spins',
    logo: 'https://storage.googleapis.com/brand-images/CasinoGodsLogo.svg',
    terms: 'New players only, 18+ T&Cs and wagering requirments apply',
    comment: 'Trending',
    link: 'https://casinogods.tracking-genesisaffiliates.com/redirect.aspx?pid=191371&bid=6485&var=',
    rating: 1878,
    star: 4,
  },

  // {
  //   name: 'novibet',
  //   linkName: 'novibet',
  //   offer: '100% Up To £150 + 15 Free Spins',
  //   logo: 'https://storage.googleapis.com/brand-images/NovibetLogoMobile.svg',
  //   terms: 'New players only, 18+ T&Cs and wagering requirments apply',
  //   comment: 'Huge Game Variety',
  //   link: link[0].url,
  //   rating: 659,
  //   star: 4,
  // },

  {
    name: '32Red',
    linkName: '32red',
    offer: 'Get 150% Up To £150',
    logo: 'https://storage.googleapis.com/brand-images/ThirtyTwoRedLogoDesktop.svg',
    terms: 'New players only, 18+ T&Cs and wagering requirments apply',
    comment: 'Gigantic Jackpots ',
    link: 'https://dspk.kindredplc.com/redirect.aspx?pid=31978916&bid=30299&alo=',
    rating: 2226,
    star: 3,
  },

  {
    name: 'Grosvenor Casino',
    linkName: 'grosvenorcasinos',
    offer: 'Deposit £20, Play with £50',
    logo: 'https://storage.googleapis.com/brand-images/GrosvenorCasinosLogoMobile.svg',
    terms: 'New players only, 18+ T&Cs and wagering requirments apply',
    comment: 'Big Bonus',
    link: 'https://ads.grosvenorcasinos.com/redirect.aspx?bid=4496&pid=1781550&sref=SUBID&SUBID=',
    rating: 2867,
    star: 3,
  },

  {
    name: 'The Sun Vegas',
    linkName: 'thesunvegas',
    offer: 'Get 100% Bonus Up To £300',
    logo: 'https://storage.googleapis.com/brand-images/TheSunVegasLogoDesktop.svg',
    terms: 'New players only, 18+ T&Cs and wagering requirments apply',
    comment: 'Quick Payouts',
    link: 'https://online.nethive.com/page?member=AmotisMedia&campaign=DEFAULT&channel=DEFAULT&zone=60038910&lp=60301181&var1=',
    rating: 1207,
    star: 2,
  },
];

const Table = () => {
  return (
    // Categories structure will be here to break up the page
    // Each gridListing will receive a prop to tell it how many
    // brands to list

    <div>
      <Listing brands={brands} />
    </div>
  );
};

export default Table;
